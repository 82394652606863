@tailwind base;
@tailwind components;
@tailwind utilities;

/* 사이드바 collapse color 변경시 여기서 변경하면 됌 */
.ant-layout .ant-layout-sider-trigger {
  background: #4d9226;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* 사이드바 메뉴 스타일 강제 지정 */
/* 업데이트 돼면 변경될 수 있으므로 개발자도구에서 요소 클래스 네임확인해서 변경하면됌 */
.ant-menu {
  color: white;
  background-color: transparent;
}

.ant-menu > .ant-menu-item {
  color: white !important;
}
.ant-menu > .ant-menu-item:hover {
  color: #f1f1f1 !important;
  background-color: rgba(255, 255, 255, 0.12) !important;
}

.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-item-selected,
.ant-menu-submenu-title:hover {
  color: #4d9226 !important;
  background-color: rgba(255, 255, 255, 0.81) !important;
}

.ant-menu-item-only-child {
  background-color: transparent !important;
}
.ant-menu-submenu-title {
  color: white !important;
}

/* editor-js image caption delete */
.image-tool__caption {
  display: none;
}
.ce-inline-toolbar--showed {
  visibility: hidden;
}

.naver-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.naver-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: naver-spin 1s linear infinite;
}

@keyframes naver-spin {
  to {
    transform: rotate(360deg);
  }
}
